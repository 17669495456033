import request from '@/utils/request'


// 查询搜索记忆列表
export function listMemory(query) {
  return request({
    url: '/memory/memory/list',
    method: 'get',
    params: query
  })
}

// 查询搜索记忆分页
export function pageMemory(query) {
  return request({
    url: '/memory/memory/page',
    method: 'get',
    params: query
  })
}

// 查询搜索记忆详细
export function getMemory(data) {
  return request({
    url: '/memory/memory/detail',
    method: 'get',
    params: data
  })
}

// 新增搜索记忆
export function addMemory(data) {
  return request({
    url: '/memory/memory/add',
    method: 'post',
    data: data
  })
}

// 修改搜索记忆
export function updateMemory(data) {
  return request({
    url: '/memory/memory/edit',
    method: 'post',
    data: data
  })
}

// 删除搜索记忆
export function delMemory(data) {
  return request({
    url: '/memory/memory/delete',
    method: 'post',
    data: data
  })
}

// 查询搜索记忆列表6个
export function listSixMemory(query) {
  return request({
    url: '/memory/memory/adminSixList',
    method: 'get',
    params: query
  })
}
