<template>
  <div class="content">
    <a-modal
      v-model="open"
      :title="$t('临床医生.添加对象')"
      @ok="handleOk"
      :closable="false"
      class="modalClass"
      :maskClosable="false"
    >
      <a-row>
        <a-input v-model="form.name" :placeholder="$t('临床医生.对象ID')" />
      </a-row>
      <a-row style="margin-top: 16px">
        <a-input v-model="form.pcbaIdList" autofocus :placeholder="$t('临床医生.选择pcba')" @click="handleOpenMadal" />
      </a-row>
      <template slot="footer">
        <a-button class="cancel" @click="handleClose">{{ $t('通用.按钮.取消') }}</a-button>
        <a-button class="save" @click="handleSave">{{ $t('通用.按钮.保存') }}</a-button>
      </template>
    </a-modal>

    <!-- 弹出框 -->
    <!-- <pcba-list-modal-vue    ref="listModalRefs" /> -->
  </div>
</template>

<script>
// import pcbaListModalVue from './pcba-list-modal.vue'
import { addClinician, listClinician } from '@/api/clinician/clinician'
export default {
  data() {
    return {
      open: false,
      form: {
        pcbaIdList: '',
      },
    }
  },
  props: {
    id: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    // pcbaListModalVue
  },
  watch: {
    id: {
      handler(newVal) {
        console.log(newVal)
        this.form.pcbaIdList = newVal.join(',')
      },
      immediate: true,
    },
  },
  methods: {
    handleOk() {},
    openModal() {
      this.open = true
    },
    // 关闭弹窗
    handleClose() {
      this.open = false
    },
    //  sensor 输入框聚焦弹出弹框
    handleOpenMadal() {
      //  this.$refs.listModalRefs.handleOpenModal()
      this.$emit('handleOpenModal')
    },
    reset() {
      this.form = {
        name: '',
        pcbaIdList: '',
      }
    },
    // 保存表单
    handleSave() {
      this.$emit('submit', this.form)
    },
  },
}
</script>

<style scoped lang="less">
.content {
}

.cancel {
  border: none !important;
  color: #7f62ad;
  font-weight: 500;
  font-size: 16px;
}

.save {
  background-color: #7f62ad;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  border-radius: 4px;
}
</style>