<template>
  <div class="content">
    <div>
      <a-row :gutter="16">
        <a-col :span="9">
          <!--<a-input v-model="form.name" :placeholder="$t('临床医生.名称')" size="large" />-->

          <a-select
            ref="selectRef"
            allowClear
            show-search
            style="width: 100%"
            :placeholder="$t('临床医生.名称')"
            option-filter-prop="children"
            :filter-option="filterOption"
            @focus="getfocus"
            @change="selectChange"
            @search="handleSearch"
            @blur="Blur"
            size="large"
            :open="isOpen"
          >
            <a-select-option v-for="(item, index) in memoryList" :key="index">
              {{ item.searchWord }}
            </a-select-option>
          </a-select>
        </a-col>
        <a-col :span="9">
          <a-input v-model="form.sensor" :placeholder="$t('临床医生.数量')" size="large" />
        </a-col>
        <a-col :span="6" style="display: flex">
          <a-button class="btn" @click="search">{{ $t('算法运行.搜索') }}</a-button>
          <a-button class="btn" @click="reset">{{ $t('算法运行.清除') }}</a-button>
        </a-col>
      </a-row>
    </div>
    <div style="margin-top: 16px">
      <a-table
        :dataSource="dataSource"
        :columns="columns"
        :locale="{ emptyText: ' No results were found.' }"
        :loading="loading"
        :pagination="false"
      >
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :pageSizeOptions="pageSizeOptions"
        :current="form.pageNum"
        :total="total"
        :page-size="form.pageSize"
        :showTotal="(total) => getTotal(total)"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </div>
  </div>
</template>

<script>
import { pageClinician, addClinician } from '@/api/clinician/clinician'
import { mapState } from 'vuex'
import { pageMemory, listMemory, delMemory, listSixMemory } from '@/api/memory/memory'

export default {
  data() {
    return {
      memoryList: [],
      pageSizeOptions: ['10', '20', '50', '100'],
      form: {
        name: '',
        pageNum: 1,
        pageSize: 10,
        sensor: '',
      },
      dataSource: [],
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   align: 'left',
        // },
        {
          title: this.$t('临床医生.名称'),
          dataIndex: 'name',
          align: 'left',
          sorter: true,
        },
        {
          title: this.$t('临床医生.数量'),
          dataIndex: 'sensor',
          align: 'left',
          sorter: true,
        },
        // {
        //   title: 'remark',
        //   dataIndex: 'remark',
        //   align: 'left',
        // },
      ],
      loading: false,
      isOpen: false,
      total: 0,
      pageSize: 10,
      current: 1,
    }
  },
  computed: {
    // ...mapState({
    //   dataSource: (state) => state.useClinicianStore.dataSource, // 这里的 'state' 是由 Vuex 生成的全局状态对象
    // }),
  },
  created() {
    // this.$store.dispatch('useClinicianStore/getCilinicianListAction', {})
    this.getList()
  },
  methods: {
    getTotal(total) {
      return this.$t('通用.文本.共') + total + this.$t('通用.文本.条')
    },
    getList() {
      this.loading = true
      pageClinician(this.form).then((e) => {
        this.dataSource = e.data.records
        this.total = e.data.total
        this.pageSize = e.data.size
        this.current = e.data.current
        this.loading = false
      })
    },
    // 搜索
    search() {
      pageClinician(this.form).then((e) => {
        this.dataSource = e.data.records
        this.loading = false
      })
    },
    // 重置
    reset() {
      this.form = {
        name: '',
        pageNum: 1,
        pageSize: 10,
        sensor: '',
      }
      this.$refs.selectRef.value = ''
      pageClinician(this.form).then((e) => {
        this.dataSource = e.data.records
        this.loading = false
      })
    },
    //获得焦点
    getfocus(e) {
      this.sixMemoryList()
    },
    //失去焦点
    losefocus() {
      this.memoryList = []
    },

    //查询联词搜索
    sixMemoryList() {
      let param = {}
      param.meunType = 'CLINICIAN'
      listSixMemory(param).then((response) => {
        this.memoryList = response.data
        if (this.memoryList.length == 0) {
          this.isOpen = false
        } else {
          this.isOpen = true
        }
      })
    },
    filterOption(input, option) {
      // console.log(input,option);
      this.form.name = input
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    selectChange(e) {
      this.form.name = this.memoryList[e].searchWord
      this.$refs.selectRef.value = this.memoryList[e].searchWord

      this.isOpen = false
    },
    handleSearch(e) {
      if (e != '') {
        this.form.name = e
        this.$refs.selectRef.value = e
      } else {
        this.form.name = ''
        this.$refs.selectRef.value = undefined
      }
    },
    Blur() {
      this.isOpen = false
    },

    onShowSizeChange(current, pageSize) {
      // console.log(current ,pageSize )
      this.form.pageNum = current
      this.form.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      // console.log(current , pageSize)
      this.form.pageNum = current
      this.form.pageSize = pageSize
      this.getList()
    },
  },
}
</script>

<style scoped lang="less">
.content {
  .btn {
    // width: 108px;
    height: 38px;
    align-items: center;
    background-color: #7f62ad;
    border-color: #7f62ad;
    box-shadow: none !important;
    display: flex;
    // white-space: pre;
    color: #fff;
    border-radius: 0.25rem;
    font-weight: 500;
    font-size: 16px;
    margin: 0px 5px;
  }
}
</style>