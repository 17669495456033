<template>
  <div>
    <a-modal
      v-model="openmodal"
      :title="$t('临床医生.添加pcba标题')"
      @ok="handleOk"
      width="75%"
      @cancel="handleClose"
      :maskClosable="false"
    >
      <a-table :row-selection="rowSelection" :columns="columns" :data-source="data"> </a-table>
      <template v-slot:footer>
        <a-button  @click="handleClose" class="cancleBtn">{{$t('通用.按钮.取消')}}</a-button>
        <a-button  @click="handleOk" class="enterBtn">{{$t('通用.按钮.确定')}}</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { pagePcba } from '@/api/pcba/pcba'
export default {
  data() {
    return {
      openmodal: false,
      selectedRowKeys: [],
      idList: [],
      columns: [
        {
          title: this.$t('临床医生.序列号'),
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: this.$t('临床医生.针ID'),
          dataIndex: 'fixtureId',
          key: 'fixtureId',
        },
        // {
        //   title: '创建时间',
        //   dataIndex: 'createTime',
        //   key: 'createTime',
        // },
        // {
        //   title: '开始时间',
        //   dataIndex: 'startDate',
        //   key: 'startDate',
        // },
        // {
        //   title: 'batteryV',
        //   dataIndex: 'batteryV',
        //   key: 'batteryV',
        // },
        // {
        //   title: 'biasVHigh',
        //   dataIndex: 'biasVHigh',
        //   key: 'biasVHigh',
        // },
        // {
        //   title: 'remark',
        //   dataIndex: 'remark',
        //   key: 'remark',
        // },
      ],
      data: [],
    }
  },
  computed: {
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          this.idList = []
          // this.selectedRowKeys = selectedRows
          this.idList = selectedRows.map((item) => {
            return item.id
          })
          // console.log('idList',this.idList)
          // console.log( this.selectedRowKeys);
        },
      }
    },
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      pagePcba({
        serialNumber: undefined,
        fixtureId: undefined,
        startDate: undefined,
        biasVHigh: undefined,
        biasVLow: undefined,
        ciHigh: undefined,
        ciLow: undefined,
        ciDraw: undefined,
        batteryV: undefined,
        cpOn: undefined,
        pageNum: 1,
        pageSize: 10,
      }).then((e) => {
        console.log('pcba', e)
        this.data = e.data.records
      })
    },
    handleOk() {
      this.openmodal = false
      this.$emit('Save', this.idList)
    },
    handleOpenModal() {
      this.openmodal = true
    },
    handleClose() {
      this.openmodal = false
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys

      this.selectedRowKeys.forEach((e) => {
        this.idList = []
        this.idList.push(Number(this.data[e].id))
      })
      console.log('i', this.idList)
    },
    // rowSelection(selectedRowKeys, selectedRows) {
    //   console.log(selectedRowKeys, selectedRows)
    // },
  },
}
</script>

<style scoped lang="less">
/deep/.cancleBtn{
     background-color: #fff;
     border: 1px solid rgb(127, 98, 173);
     border-radius: .25rem;
     color: rgb(127, 98, 173);
}

/deep/.enterBtn{
     background-color:rgb(127, 98, 173);
     border: 1px solid rgb(127, 98, 173);
     border-radius: .25rem;
     color: #fff;
}
</style>