<template>
  <div class="content">
    <div>
      <a-card style="width: 100%; border-radius: 4px">
        <div class="top-title">
          <!-- <a-icon type="smile" style="font-size: 38px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
          <svg
            aria-hidden="true"
            focusable="false"
            data-prefix="fas"
            data-icon="briefcase"
            class="svg-inline--fa fa-briefcase fa-fw fa-lg"
            role="img"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
            width="32px"
            height="32px"
            style="margin-right: 5px"
          >
            <path
              fill="currentColor"
              d="M184 48H328c4.4 0 8 3.6 8 8V96H176V56c0-4.4 3.6-8 8-8zm-56 8V96H64C28.7 96 0 124.7 0 160v96H192 320 512V160c0-35.3-28.7-64-64-64H384V56c0-30.9-25.1-56-56-56H184c-30.9 0-56 25.1-56 56zM512 288H320v32c0 17.7-14.3 32-32 32H224c-17.7 0-32-14.3-32-32V288H0V416c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V288z"
            ></path>
          </svg>
          <!-- Clinician -->
          {{ $t('临床医生.临床医生') }}
        </div>
        <div class="top-middle">{{ $t('临床医生.在临床研究中监测受试者') }}</div>
        <div>
          <a-button type="link" class="btn" @click="openModal"
            >{{ $t('临床医生.添加主题') }}
            <!-- <a-icon type="plus" style="font-size: 20px; color: rgb(127, 98, 173); margin-right: 20px" /> -->
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="circle-plus"
              class="svg-inline--fa fa-circle-plus fa-fw"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              width="16px"
              height="16px"
              style="margin-left:5px"
            >
              <path
                fill="currentColor"
                d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM232 344V280H168c-13.3 0-24-10.7-24-24s10.7-24 24-24h64V168c0-13.3 10.7-24 24-24s24 10.7 24 24v64h64c13.3 0 24 10.7 24 24s-10.7 24-24 24H280v64c0 13.3-10.7 24-24 24s-24-10.7-24-24z"
              ></path>
            </svg>
          </a-button>
        </div>
      </a-card>
    </div>
    <div style="margin-top: 16px">
      <a-card style="width: 100%; border-radius: 4px">
        <div>
          <Table ref="tableRefs" />
        </div>
      </a-card>
    </div>

    <!-- 弹出框 -->
    <Modal ref="ModalRefs" @handleOpenModal="openPcbaListModal" :id="idList" @submit="onSubmit" />

    <!-- 弹出框 -->
    <pcba-list-modal ref="listModalRefs" @Save="onSaveId" />
  </div>
</template>

<script>
import Table from './modules/table.vue'
import Modal from './modules/Modal.vue'
import PcbaListModal from './modules/pcba-list-modal.vue'
import { addClinician, listClinician } from '@/api/clinician/clinician'
import { mapActions } from 'vuex'

export default {
  data() {
    return {
      idList: [],
    }
  },
  components: {
    Table,
    Modal,
    PcbaListModal,
  },
  created() {
    this.sixMemoryList()
  },
  methods: {
    ...mapActions({}),
    openModal() {
      this.$refs.ModalRefs.openModal()
    },
    // 打开 pcba list 弹框
    openPcbaListModal() {
      this.$refs.listModalRefs.handleOpenModal()
    },
    // 获取id
    onSaveId(idList) {
      console.log('List', idList)
      this.idList = idList
    },
    onSubmit(e) {
      addClinician({
        name: e.name,
        pcbaIdList: e.pcbaIdList.split(','),
      }).then((res) => {
        console.log(res)
        this.$refs.ModalRefs.handleClose()
        this.$message.success('添加成功')
        this.$refs.tableRefs.getList()
        this.$refs.ModalRefs.reset()
        this.$store.dispatch('useClinicianStore/getCilinicianListAction', {})
      })
    },
  },
}
</script>

<style scoped lang="less">
.content {
  width: 100%;
  height: 100%;

  .top-title {
    align-items: center;
    display: flex;
    font-size: 1.6rem;
    font-weight: 600;
    color: #212529;
  }

  .top-middle {
    margin-top: 8px;
    font-size: 16px;
    color: #212529;
    font-family: Poppins, sans-serif;
  }

  .btn {
    color: rgb(127, 98, 173);
    font-weight: 500;
    font-size: 16px;
    margin-top: 16px;
    align-items: center;
    display: flex;
  }
}
</style>